<section *ngIf="!ipInformation && !errorMessage">
  <h3>loading...</h3>
</section>
<section *ngIf="ipInformation" id="ip-information">
  <div id="ip-information__current">
    <h3>Your IP Address is:</h3>
    <h1>
      {{ipInformation.current.ip}}
    </h1>
  </div>
  <h3>Details</h3>
  <div id="ip-information__current__details">
    <div>Hostname: {{ipInformation.current.hostname}}</div>
    <div>
      Country: {{ipInformation.current.country || '-'}}
      <span *ngIf="ipInformation.current.countryCode" class="flag flag-{{ipInformation.current.countryCode}}"></span>
    </div>
    <div>Timestamp: {{ipInformation.current.timestamp | date:'yyyy-MM-dd HH:mm:ss Z'}}</div>
  </div>
  <div *ngIf="ipInformation.history && ipInformation.history.length > 1" id="ip-information__history">
    <h3>Previous ({{ipInformation.history.length - 1}}):</h3>
    <div id="ip-information__history__records">
      <div *ngFor="let record of ipInformation.history | slice: 1" class="ip-information__history__record">
        <div>IP: {{record.ip}}</div>
        <div>Hostname: {{record.hostname}}</div>
        <div>
          Country: {{record.country || '-'}}
          <span *ngIf="record.countryCode" class="flag flag-{{record.countryCode}}"></span>
        </div>
        <div>Timestamp: {{record.timestamp | date:'yyyy-MM-dd HH:mm:ss Z'}}</div>
      </div>
    </div>
  </div>
</section>
<section *ngIf="errorMessage">
  <h3>An error occured:</h3>
  {{errorMessage}}
</section>
