import {Component, OnInit} from '@angular/core';
import {IpInformationService} from "../ip-information.service";
import {IpInformation} from "../ipInformation";

@Component({
  selector: 'app-ip-information',
  templateUrl: './ip-information.component.html',
  styleUrls: ['./ip-information.component.css']
})
export class IpInformationComponent implements OnInit {
  ipInformation?: IpInformation;
  errorMessage: string = '';
  isLoading: boolean = true;

  constructor(private ipInformationService: IpInformationService) {
  }

  ngOnInit(): void {
    this.ipInformationService.getIpInformation().subscribe(
      data => this.ipInformation = data,
      error => this.errorMessage = error,
      () => this.isLoading = false
    )
  }

}
