import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IpInformation} from "./ipInformation";
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpInformationService {

  constructor(private http: HttpClient) {
  }

  getIpInformation(): Observable<IpInformation> {
    return this.http.get<IpInformation>('/api/ip')
      .pipe(
        catchError(this.handleError)
      );
  }

  handleError(error: any) {
    console.error(error);
    return throwError(error.error || 'Internal Server Error');
  }
}
